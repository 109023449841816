import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_nl-nl",
  "shopName": "lusini",
  "locale": "nl-nl",
  "rootCategoryId": "EMG",
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "links": {
    "contact": "/klantenservice/contact/"
  },
  "i18n": {
    "currency_ISO": "EUR",
    "locale": "nl-NL",
    "country": "NL"
  },
  "orderMinimumB2C": 30,
  "slugifyLocale": "nl",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "nl-nl",
      "hrefLang": "nl"
    },
    {
      "configFolder": "lusini",
      "locale": "nl-be",
      "hrefLang": "nl-be"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-nl.svg",
      "newsletter_intentlayer_picture": "https://res.cloudinary.com/lusini/image/upload/v1724665736/application/NewsletterPopupAB/discount-reduction-nl-nl.svg"
    },
    "shopware": {
      "salutations": [
        "mr",
        "ms"
      ],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "accessKey": {
        "b2b": "SWSCKHXTZK25RBIKFYQTT0U7EW",
        "b2c": "SWSC74TCVJ22GZVS9UUGDVEJ5F"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/nl-nl/b2b",
        "b2c": "https://www.lusini.com/nl-nl/b2c"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "a72829a6281955d5f8febc70eeb0a8de8a9f4841db410f4012d1fb4bf2dc5ffa",
      "campaignID": "9879544"
    },
    "trustShop": {
      "placeholderImg": "https://res.cloudinary.com/lusini/image/upload/c_scale,w_100/v1670412947/application/icons/trusted-shops-guarantee.svg",
      "id": "XAFAC4FAE5562DE0CE89CD690E31F4863",
      "url": "https://widgets.trustedshops.com/js/",
      "urlPostfix": ".js"
    },
    "zenloop": {
      "id": "T0dFM016SXhNalF0TjJWaU5DMDBOakZpTFRnek5HTXRNemxpWkdJek1UWXlaakJp"
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    },
    "linkedIn": {
      "id": 4713842
    }
  },
  "index": {
    "products": "prod_lusini_nl_NL_products",
    "productsPriceAsc": "prod_lusini_nl_NL_products_priceAsc",
    "productsPriceDesc": "prod_lusini_nl_NL_products_priceDesc",
    "categories": "prod_lusini_nl_NL_categories",
    "series": "prod_lusini_nl_NL_series",
    "productsSuggestion": "prod_lusini_nl_NL_products_query_suggestions",
    "productsLatest": "prod_lusini_nl_NL_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "Wit": "white",
    "Zwart": "black",
    "Grijs": "grey",
    "Zilver": "silver",
    "Bruin": "brown",
    "Blauw": "blue",
    "Meerkleurig": "multicolor",
    "Rood": "red",
    "Beige": "beige",
    "Groen": "green",
    "Transparent": "transparent",
    "Geel": "yellow",
    "goud": "gold",
    "Oranje": "orange",
    "Rosa/Roze": "lilac"
  },
  "features": {
    "devMode": false,
    "trustShop": true,
    "zenloop": true,
    "linkedIn": true,
    "b2c": true,
    "userLikeWidgetUrl": "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/558079fd8693514d87f59a4aaa74c4be4ab46b2a8ae701b438efb53fdfa3cc6b.js",
    "customizableUrl": "https://www.lusini.com/nl-nl/klantenservice/onze-services/personalisatie/",
    "b2cHomePage": true,
    "notificationBar": true,
    "showNewsletterIntentLayer": true,
    "chamberOfCommerceInfo": true,
    "showProductRecommendationInfoTextB2B": true,
    "showProductRecommendationInfoTextB2C": true,
    "showCartInfoboxFrom": "2024-12-11T08:00:00",
    "showCartInfoboxTo": "2025-01-08T01:00:00"
  },
  "abTests": {},
  "showTelephoneContactOnFailedCheckoutThreshold": 0
}
export default config